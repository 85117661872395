import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

import { LotStatus, TypesenseLot } from '@nx/global-types'

import { TypesenseClientInstance } from '../typesenseClient'
import {
  buildFilterString,
  extractDocuments,
  extractFacets,
  generateFacetsBy,
  generateFacetsQueries,
} from '../typesenseHelpers'

export type TypesenseLotResponse = Omit<
  SearchResponse<TypesenseLot>,
  'hits'
> & {
  facets: Record<string, Record<string, number>>
  hits: TypesenseLot[]
  nbPages: number
  nbHits: number
}

export type GetTypesenseLotsParams = SearchParams & {
  collection?: 'lots'
  auctionId?: string
  minPrice?: number
  maxPrice?: number
  minGBPPrice?: number
  maxGBPPrice?: number
  departments?: string[]
  chronology?: 'past' | 'future'
  isWithoutReserve?: boolean
  excludeWithdrawn?: boolean
  excludeUnsold?: boolean
  country?: string[]
  brand?: string[] | null
  lotItemIds?: number[] | string[]
  groups?: string[]
  filters?: string
}

export function getTypesenseLots({
  typesenseRequest,
  executeTypesenseQueries,
}: TypesenseClientInstance) {
  return async ({
    collection = 'lots',
    auctionId,
    minPrice,
    maxPrice,
    minGBPPrice,
    maxGBPPrice,
    departments = [],
    chronology,
    excludeWithdrawn,
    excludeUnsold,
    country = [],
    brand = [],
    lotItemIds = [],
    groups = [],
    isWithoutReserve,
    filter_by = '',
    query_by = ['heading'].join(','),
    sort_by = 'lotNo.number:asc',
    page = 0,
    per_page = 24,
    q = '',
    ...searchArgs
  }: GetTypesenseLotsParams): Promise<TypesenseLotResponse> => {
    const facetFiltersRelation: Record<string, (string | number)[]> = {
      'country.name': [country].flat().filter(Boolean),
      'department.name': [departments].flat().filter(Boolean),
      brand: [brand].flat().filter(Boolean),
      groups: [groups].flat().filter(Boolean),
    }

    if (chronology) {
      facetFiltersRelation['status'] =
        chronology === 'future'
          ? [LotStatus.new]
          : [`${LotStatus.sold}`, `${LotStatus.withoutResults}`]
    }

    const filters = [
      buildFilterString('auctionId', auctionId ? `${auctionId}` : ''),
      buildFilterString(
        'flags.isWithoutReserve',
        isWithoutReserve ? 'true' : ''
      ),
      buildFilterString(
        'status',
        excludeWithdrawn
          ? {
              value: LotStatus.withdrawn,
              exclude: true,
            }
          : ''
      ),
      buildFilterString(
        'status',
        excludeUnsold
          ? {
              value: LotStatus.unsold,
              exclude: true,
            }
          : ''
      ),
      buildFilterString('lotItemId', lotItemIds),
      filter_by,
    ]

    const facetFilters = Object.entries(facetFiltersRelation).map(
      ([key, value]) => buildFilterString(key, value)
    )

    const facetBy = generateFacetsBy(facetFiltersRelation)

    if (minPrice && maxPrice) {
      filters.push(
        `(price.estimateLow:${minPrice} TO ${maxPrice} || price.estimateHigh:${minPrice} TO ${maxPrice})`
      )
    }

    if (minGBPPrice && maxGBPPrice) {
      filters.push(
        `(price.GBPLowEstimate:${minGBPPrice} TO ${maxGBPPrice} || price.GBPHighEstimate:${minGBPPrice} TO ${maxGBPPrice})`
      )
    }

    const includeFields = [
      '*', // retrieves all attributes
    ]
    const excludeFields = ['footnotes', 'catalogDesc']

    const finalFilters = [...filters, ...facetFilters]
      .filter(Boolean)
      .join(' && ')

    const mainParams: MultiSearchRequestSchema = {
      include_fields: includeFields.join(','),
      exclude_fields: excludeFields.join(','),
      filter_by: filters.filter(Boolean).join(' && '),
      facet_by: facetBy.join(','),
      query_by,
      sort_by,
      page: Math.max(1, page),
      per_page,
      max_facet_values: 99999,
      q,
      ...searchArgs,
    }

    const extraParams = generateFacetsQueries(facetFiltersRelation, mainParams)

    const mainResponse = await typesenseRequest<SearchResponse<TypesenseLot>>(
      collection,
      { ...mainParams, filter_by: finalFilters }
    )

    const extraResponses = extraParams.map((params) =>
      typesenseRequest<SearchResponse<TypesenseLot>>(collection, params)
    )

    const [mainResult, ...extraResults] = await Promise.all([
      mainResponse,
      ...extraResponses,
    ])

    executeTypesenseQueries()

    const facetCounts = [
      ...(mainResult.facet_counts ?? []),
      ...extraResults.flatMap((extra) => extra.facet_counts),
    ].filter(Boolean)
    return {
      ...extractDocuments(mainResult),
      facets: extractFacets(facetCounts),
      facet_counts: facetCounts,
      nbPages: Math.floor(mainResult.found / per_page) || 0,
      nbHits: mainResult.found,
    }
  }
}
