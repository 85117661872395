import {
  keepPreviousData as keepPreviousDataFn,
  useQuery,
} from '@tanstack/react-query'

import { TypesenseClientInstance } from '../typesenseClient'
import { getTypesenseLotsByIDs } from './getTypesenseLotsByIDs'

interface UseGetTypesenseLotsByIDsParams {
  client: TypesenseClientInstance
  lotUniqueIds: number[]
  keepPreviousData: boolean
  enabled?: boolean
  staleTime?: number
  refetchOnMount?: boolean
  objectIDs?: never
}

export const useGetTypesenseLotsByIDs = ({
  client,
  lotUniqueIds,
  keepPreviousData,
  enabled,
  staleTime,
  refetchOnMount,
}: UseGetTypesenseLotsByIDsParams) =>
  useQuery({
    queryKey: ['lots', { isTypesenseEnabled: true, lotUniqueIds }],
    queryFn: () => getTypesenseLotsByIDs(client, lotUniqueIds),
    placeholderData: keepPreviousData ? keepPreviousDataFn : undefined,
    enabled,
    staleTime,
    refetchOnMount,
  })
