/* istanbul ignore file */
export function withResolversPolyfill() {
  Promise.withResolvers ||
    // @ts-expect-error: unknown polyfill
    (Promise.withResolvers = function withResolvers() {
      let a, b
      const c = new this(function (resolve, reject) {
        a = resolve
        b = reject
      })
      return { resolve: a, reject: b, promise: c }
    })
}
