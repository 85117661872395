import {
  getTypesenseAuctionsByIDs as getTypesenseAuctionsByIDsLib,
  getTypesenseAuctions as getTypesenseAuctionsLib,
  getTypesenseHeadlines as getTypesenseHeadlinesLib,
  getTypesenseLotsByIDs as getTypesenseLotsByIDsLib,
  getTypesenseLots as getTypesenseLotsLib,
  typesenseClient,
  useGetTypesenseAuctionsByIDs as useGetTypesenseAuctionsByIDsLib,
  useGetTypesenseHeadlines as useGetTypesenseHeadlinesLib,
  useGetTypesenseLotsByIDs as useGetTypesenseLotsByIDsLib,
} from '@nx/typesense'

import { config, configHeadline } from './getConfig'

const client = typesenseClient(config)
const clientHeadline = typesenseClient(configHeadline)

/**
 * AUCTIONS
 */
// wrapper methods for @nx lib methods for algolia search
/* istanbul ignore next */
export const getTypesenseAuctions = getTypesenseAuctionsLib(client)
/* istanbul ignore next */
export const getTypesenseAuctionsByIDs = (ids: string[] | number[]) =>
  getTypesenseAuctionsByIDsLib(client, ids)
/* istanbul ignore next */
export const useGetTypesenseAuctionsByIDs = (
  args: Omit<Parameters<typeof useGetTypesenseAuctionsByIDsLib>[0], 'client'>
) => useGetTypesenseAuctionsByIDsLib({ client, ...args })

/**
 * LOTS
 */
/* istanbul ignore next */
export const getTypesenseLots = getTypesenseLotsLib(client)
/* istanbul ignore next */
export const getTypesenseLotsByIDs = (ids: string[] | number[]) =>
  getTypesenseLotsByIDsLib(client, ids)
/* istanbul ignore next */
export const useGetTypesenseLotsByIDs = (
  args: Omit<Parameters<typeof useGetTypesenseLotsByIDsLib>[0], 'client'>
) => useGetTypesenseLotsByIDsLib({ client, ...args })

/**
 * HEADLINES
 */

/* istanbul ignore next */
export const getTypesenseHeadlines = getTypesenseHeadlinesLib(clientHeadline)
/* istanbul ignore next */
export const useGetTypesenseHeadlines = (
  args: Omit<Parameters<typeof useGetTypesenseHeadlinesLib>[0], 'client'>
) => useGetTypesenseHeadlinesLib({ client: clientHeadline, ...args })
