import { getTypesenseAuctions } from './getTypesenseAuctions'

export const getTypesenseAuctionsByIDs = async (
  typesenseConfig: Parameters<typeof getTypesenseAuctions>[0],
  auctionIds: (string | number)[]
) => {
  try {
    const response = await getTypesenseAuctions(typesenseConfig)({
      brand: null,
      filter_by: `id:[${auctionIds.join(',')}]`,
    })

    const hitsById = new Map(response.hits.map((hit) => [String(hit.id), hit]))

    return auctionIds.map((id) => hitsById.get(String(id)) || null)
  } catch {
    return []
  }
}
